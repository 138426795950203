header {
  background-image: url("/assets/images/codicille-header.jpg");
  height: 300px;


  @media screen and (max-width: 991px) {
    height: 200px;
  }

img {
    height: 300px;
    margin-left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 991px) {
      height: 200px;
    }
  }
}

h1, h2, h3, h4, h5, h6, p, li, ul, a {
	font-family: "Avenir Next", Arial, "Helvetica Neue", sans-serif;
}

h2 {
	padding-top: 1.5rem;
}


.navbar-brand img {
  height: 30px;
}

.image-ouvrage {
  width: 275px;
  aspect-ratio: initial;
  border: 1px gray solid;
  image-rendering: -webkit-optimize-contrast;
}

.frame-container {
	padding-top: 1.5rem;
  position: relative;
  overflow: hidden;
  width: 100%;
	}
	
.frame-itself {
  width: 100%;
  height: 1100px;
  
  @media screen and (max-width: 991px) {
	height : 750px;
  }
}